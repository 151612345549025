.header_heading {
  color: rgb(5, 180, 20);
  font-size: 40px;
  font-weight: 600;
  font-family: cursive;
}

.WebHead {
  background: rgb(2, 17, 2);
  height: 100px;
}

.WebHeads {
  background: rgb(183 185 59 / 67%);
}

.Form_background {
  /* background: linear-gradient(#0ace04bb, #bfd8bebb ); */
  /* background: #678e66; */
  height: 100%;
}

.company_logo {
  height: auto;
  margin-top: 15px;
  width: 300px;
}

.login_heading {
  margin-top: 45px;
  color: rgb(17, 129, 116);
  font-weight: bold;
}

input[type='email'],
input[type='text'],
input[type='number'],
input[type='password'],
input[type='submit'] {
  padding: 8px 50px;
  margin-top: 10px;
  border-radius: 20px;
  border: none;
  z-index: 1;
}

.email_icon {
  font-size: 20px;
  margin-top: 5px;
  z-index: 2;
}

.icon_bg {
  height: 35px;
  width: 35px;
  background: rgb(207, 206, 206);
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  display: inline-flex;
  margin-right: -35px;
}

.login_btns {
  height: 40px;
  width: 270px;
  margin-top: 10px;
  margin-left: 20px;
  border-radius: 20px;
  align-items: center;
  border: none;
  background: blue;
  margin-right: 30px;
  color: white;
}

.login_btns:hover {
  background: rgba(0, 132, 255, 0.582);
}

.language_button {
  height: 40px;
  width: 150px;
  background: rgb(2, 114, 99);
  color: white;
  text-decoration: none;
  border-radius: 20px;
  border: none;
}

.quiz_box {
  background: rgb(11, 153, 146);
  margin-top: 14px;
  border-radius: 2px;
}

h4.timer_text {
  text-align: center;
  margin-top: 10px;
}

/* .hr_ques{
    border: 2px solid rgb(0, 0, 0);
    margin-top: 5px;
} */

.options {
  height: auto;
  color: black;
  font-size: 20px;
  padding: 5px;
}

.number_btn {
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin: 5px;
  border: none;
  border-radius: 3px;
  background: rgb(197, 197, 202);
}

.number_btn {
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin: 5px;
  border: none;
  border-radius: 3px;
}

.number_btn:hover {
  background: rgb(196, 190, 190);
}

.not_attempt {
  background: red;
  height: 15px;
  width: 20px;
  border: none;
}

.marked {
  background: yellow;
  height: 15px;
  width: 20px;
  border: none;
}

.attempt {
  background: green;
  height: 15px;
  width: 20px;
  border: none;
}

.suggetion {
  font-size: 20px;
  color: white;
}

.finish_test,
.previous_btn {
  border: none;
  background-color: rgb(12, 120, 163);
  color: white;
  height: 40px;
  width: 110px;
  border-radius: 30px;
}

.previous_btns {
  border: none;
  background-color: rgb(16, 35, 177);
  color: rgb(255, 254, 254);
  height: 40px;
  width: 110px;
  border-radius: 30px;
}

.previous_btns .prebtn {
  color: white;
  text-decoration: none;
}

.next_btns {
  border: none;
  background-color: rgb(22, 121, 9);
  color: white;
  height: 40px;
  width: 110px;
  border-radius: 30px;
  float: right;
}

.next_btns .nextbtn {
  color: white;
  text-decoration: none;
}

.next_btn {
  border: none;
  background-color: rgb(10, 150, 5);
  color: white;
  height: 40px;
  width: 125px;
  border-radius: 30px;
  margin-left: 10px;
}

@media screen and (max-width: 560px) {
  .next_btn {
    margin-top: 5px;
    width: 100%;
    border: none;
    background-color: rgb(26, 12, 12);
    color: white;
    height: 40px;
    width: 100%;
    margin-left: 0px;
    border-radius: 30px;
  }
}

.submit_btn {
  margin-left: -122px;
  border: none;
  background-color: rgb(17, 70, 1);
  color: white;
  height: 40px;
  width: 110px;
  border-radius: 30px;
  margin-left: 10px;
}

@media screen and (max-width: 560px) {
  .submit_btn {
    margin-top: 5px;
    width: 100%;
    border: none;
    background-color: rgb(26, 12, 12);
    color: white;
    height: 40px;
    width: 100%;
    margin-left: 0px;
    border-radius: 30px;
  }
}

.remark_btn {
  /* margin-left: -122px; */
  border: none;
  background-color: rgb(114, 122, 2);
  color: white;
  height: 40px;
  width: 110px;
  margin-left: 10px;
  border-radius: 30px;
}

@media screen and (max-width: 560px) {
  .remark_btn {
    margin-top: 5px;
    width: 100%;
    border: none;
    background-color: rgb(26, 12, 12);
    color: white;
    height: 40px;
    margin-left: 0px;
    width: 100%;
    border-radius: 30px;
  }
}

.score {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background: green;
  text-align: center;
  line-height: 200px;
  color: white;
  font-size: 70px;
  font-weight: bolder;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-summary {
  color: white;
  font-size: 20px;
  padding: 10px;
}

.question_result {
  border: 1px solid white;
  color: white;
  width: 80%;
  height: 70vh;
  overflow: scroll;
}

.review-que-section {
  margin: 20px;
}

@media screen and (max-width: 560px) {
  .finish_test,
  .previous_btn {
    margin-top: 7px;
    width: 100%;
  }
}

@media screen and (max-width: 560px) {
  .header_heading {
    font-size: 20px;
  }
}

.img_main {
  height: 100px;
}

.lottie_file {
  height: 450px;
  border-radius: 10px;
}

.card-img-tops {
  height: 100px;
  width: 100px;
}

.number_btn_active {
  height: 40px;
  width: 40px;
  margin-top: 5px;
  margin: 5px;
  border: 1px solid white;
  border-radius: 3px;
  background: rgb(134, 134, 175);
}

.correctAnswerMark {
  display: inline-block;
  color: blue;
}

.ansTag {
  display: inline-block;
  border: 1px solid;
  padding: 7px;
  border-radius: 50%;
}
.selectedCorrectTag {
  display: inline-block;
  border: 1px solid;
  padding: 5px;
  border-radius: 50%;
}

.card_center_class {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.img_main_login {
  height: 140px;
  width: 300px;
  border-radius: 20px;
}

/* ///////////////////////////////////////////////////////////////////// */

.img_home_footer {
  width: 200px;
  height: 100px;
  border-radius: 20px;
}

.footer_links a {
  color: white;
  text-decoration: none;
}

.footer_links a:hover {
  color: rgb(180, 212, 38);
}

.footer_icons {
  color: white;
  margin: 10px;
  font-size: 30px;
}

.footer_icons_facebook {
  color: #3b5998;
  margin: 10px;
  font-size: 30px;
}

.webcam {
  height: 200px;
  width: 250px;
}

.quiz-final-section {
  margin: auto;
  width: 40%;
  margin-top: 5%;
}

/* _________________________________________admin dashboard tab css___________________________________ */

@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700|Raleway:300,400';

/* Tabs*/
/* section {
    padding: 60px 0;
} */

section .section-title {
  text-align: center;
  color: #007b5e;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#tabs {
  background: #007b5e;
  color: #eee;
}
#tabs h6.section-title {
  color: #eee;
}

#tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #f3f3f3;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 4px solid !important;
  font-size: 20px;
  font-weight: bold;
}
#tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #eee;
  font-size: 20px;
}
/* _________________________________________admin dashbaord tab css end______________________________ */

.quiz_attemp_result_admin {
  color: black;
}
.overall_result_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* @import 'material-icons/iconfont/material-icons.css'; */
/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext'); */
/* body {
  background-color: #E3F2FD;
} */
.chatbot-toggler {
  position: fixed;
  right: 40px;
  bottom: 35px;
  height: 50px;
  width: 50px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  background-color: #307c3d;
  border-radius: 50%;
  transition: all 0.2s ease;
}
.show-chatbot .chatbot-toggler {
  transition: rotate(90deg);
}
.chatbot-toggler span {
  position: absolute;
}
.show-chatbot .chatbot-toggler span:first-child,
.chatbot-toggler span:last-child {
  opacity: 0;
}
.show-chatbot .chatbot-toggler span:last-child {
  opacity: 1;
}
.chatbot {
  position: fixed;
  right: 40px;
  bottom: 100px;
  width: 420px;
  transform: scale(0.5);
  opacity: 0;
  pointer-events: none;
  height: 500px;
  overflow: hidden;
  border-radius: 15px;
  transform-origin: bottom right;
  box-shadow: 0 0 128px 0 rgb(0, 0, 0, 0, 1), 0 32px 64px -48px rgb(0, 0, 0, 0, 5);
  background-color: #2a7552;
  transition: all 0.5 ease;
}

.show-chatbot .chatbot {
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
}
.chatbot header {
  background-color: rgb(183 185 59 / 67%);

  padding: 16px 0;
  text-align: center;
  position: relative;
}
.chatbot header h2 {
  color: #fff;
  font-size: 1.4remj;
}
.chatbot header span {
  position: absolute;
  right: 20px;
  top: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  transform: translateY(-50%);
}
.chatbot .chatbot {
  height: 510px;
  overflow-y: auto;
  padding: 30px 20px 100px;
}
.chatbot .chat {
  display: flex;
}
.chatbot .incoming span {
  height: 32px;
  width: 32px;
  color: #fff;
  align-self: flex-end;
  background-color: #6640d8;
  text-align: center;
  line-height: 32px;
  border-radius: 4px;
  margin: 0 10px 7px 0;
}
.chatbot .outgoing {
  margin: 20px 0;
  justify-content: flex-end;
  margin: 0 10px 7px 0;
  border-radius: 4px;
}

.chatbot .chat p {
  white-space: pre-wrap;
  margin-top: 10px;
  color: #fff;
  font-size: 0.95rem;
  max-width: 75%;
  padding: 12px 16px;
  border-radius: 10px 10px 0 10px;
  background-color: #725cb9;
}
/* .chatbot .incoming span{
  color: #000;
  background-color: #c9c3dd;
  color: #fff;
  border-radius: 10px 10px 10px 0 ;
} */
.chatbot .outgoing span {
  white-space: pre-wrap;
  color: #fff;
  font-size: 0.95rem;
  max-width: 75%;
  padding: 12px 16px;
  border-radius: 10px 10px 0 10px;
  background-color: #592ae6;
}
.chatbot .chat_input {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  gap: 5px;
  background-color: #fff;
  padding: 5px 20px;
  border-top: 1px solid #ccc;
}
.chat_input input {
  height: 34px;
  width: 84%;
  max-height: 100px;
  border: none;
  outline: none;
  font-size: 0.95rem;
  resize: none;
  padding: 16px 15px 16px 0;
}
.chat_input span {
  color: #724ae8;
  align-self: flex-end;
  height: 35px;
  line-height: 0px;
  font-size: 1.4rem;
  cursor: pointer;
  visibility: hidden;
}
.main_area {
  overflow: auto;
  height: 320px;
  /* border: 1px solid; */
}
.chat_input input:valid ~ span {
  visibility: visible;
}
@media (max-width: 490px) {
  .chatbot {
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .chatbot .chatbot {
    height: 90%;
  }
  .chatbot header span {
    display: block;
  }
}
