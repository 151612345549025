/* header css */
.navbar-toggler {
  border: none;
}

.toggle_icon {
  height: 40px;
  width: 40px;
  border: none;
}

.toggle_icon2 {
  height: 30px;
  width: 30px;
  border: none;
}

.toggle_icon3 {
  height: 25px;
  width: 25px;
  margin: 3px;
  border: none;
}

.navbar-brand img {
  height: 45px;
  width: 100px;
}

.header-bg {
  /* background-color: #006241; */
  background-color: #363653;
}

a:hover {
  color: white !important;
}

::selection {
  color: rgb(223, 223, 223);
  background: #005c54;
}

.navbar {
  padding: 0.5px 1px;
}

.navbar-brand img {
  height: 45px;
  width: 100px;
}

.navbar-light .navbar-nav .nav-link {
  /* color: #fff4a3; */
  color: #fff;
  text-align: center;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgb(219, 219, 219);
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: white;
}

/* end of header */

/* footer css */

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #363653;
  /* background-color: #1e3932; */
  color: white;
  text-align: center;
}

.footerdown {
  background-color: #0a1538;
  height: max-content;
}

.footer-link {
  color: white;
}

.footer-link:hover {
  color: gold;
  text-decoration: none;
}

a {
  color: inherit;
  cursor: pointer;
}

/* end of footer css */

/* Section One */
.homevideo {
  width: 100%;
  height: 200px;
  margin-top: 20px;
}

/* end of section one */

/* section four */
.middle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* width: 100%; */
  text-align: center;
}

.btn {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #f1f1f1;
  margin: 20px;
  border-radius: 30%;
  box-shadow: 0 5px 15px -5px #00000070;
  color: black;
  overflow: hidden;
  position: relative;
}

.btn i {
  line-height: 40px;
  font-size: 26px;
  transition: 0.2s linear;
}

.btn:hover i {
  transform: scale(1.3);
  color: #f1f1f1;
}

.bgcolor1::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background-color: #4267b2;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}

.bgcolor2::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background-color: #1da1f2;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}

.bgcolor3::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}

.bgcolor4::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background-color: rgb(236, 48, 48);
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}

.bgcolor5::before {
  content: '';
  position: absolute;
  width: 120%;
  height: 120%;
  background-color: #25d366;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}

.btn:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}

@keyframes aaa {
  0% {
    left: -110%;
    top: 90%;
  }

  50% {
    left: 10%;
    top: -30%;
  }

  100% {
    top: -10%;
    left: -10%;
  }
}

.social_icon {
  padding: 20px;
  transform: translateY(-50%);
  margin-top: 70px;
}

/* end of section four  */

/* section three */
.exercise_box {
  background-color: rgb(152, 177, 94);
  color: white;
  height: 150px;
  width: auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exercise_box:hover {
  color: white;
}

.exercise {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu {
  position: relative;
  width: 200px;
  height: 200px;
  /* background: #0f0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.exerciesandquizzes {
  color: #ffffff;
}

.exercise-heading-color {
  color: white;
}

/* end of section three  */

/* section five */

.course_bg {
  background-color: #373a49;
  /* background-color: #d4e9e2; */
}

.html_bg {
  background-color: #d9eee1;
  padding: 10px;
  height: 100%;
}

.css_bg {
  background-color: #fff4a3;
  padding: 10px;
}

.card_button {
  font-size: 18px;
  text-decoration: none;
  background-color: #282a35;
  color: white;
  margin: auto;
  display: block;
  width: 200px;
  border: none;
  border-radius: 25px;
}

.card_button:hover {
  text-decoration: none;
  background-color: #0a1538;
}

@media screen and (max-width: 767px) {
  .html_bg {
    margin-bottom: 6px;
  }
}

/* end of section Five  */

/* sidebar */
.sidebar_bg_deisgn {
  background-color: #000000;
  padding-inline-start: 10px;
  margin-bottom: 3px;
}

.vertical-menu {
  width: 100%;
  height: 500px;
  overflow: scroll;
  padding: 0;
}

.vertical-menu a {
  /* background-color: #4a5044; */
  background-color: #3b3b3b;
  color: white;
  display: block;
  padding: 5px;
  text-decoration: none;
}

.vertical-menu a:hover {
  background-color: #009999;
  text-decoration: none;
}

.vertical-menu a.active {
  background-color: #2c2929;
  color: lightgoldenrodyellow;

}

@media screen and (min-width: 767px) {
  #mysidebar {
    display: initial;
  }

  .sidebar_bg_deisgn {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .navbar-toggler {
    display: none;
  }

  .sidebar_bg_deisgn {
    display: none;
  }
}

/* end sidebar css */

/* content area */
.programname {
  background-color: white;
  color: rgb(0, 0, 0);
  text-transform: capitalize;
  padding: 10px;
}

.contentdiv {
  /* border: 8px solid #bdd1b4; */
  border: 8px solid #3636538f;
  padding: 0.1%;
}

.page-link {
  background: #567956;
  width: 85px;
  border-radius: 5px;
  color: white;
  margin-top: 6px;
  text-align: center;
}

.page-link:hover {
  background: #1b1a1a;
  color: black;
  text-decoration: none;
}

.page-item1 {
  margin-right: auto;
}

.language_name {
  /* background-color: rgb(15, 119, 83); */
  background-color: #363653d7;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: 5px;
  font-weight: bold;
}

/* end of content area  */

/* start code plate */
.code_plate {
  width: 100%;
  background-color: black;
  color: white;
  padding: 7px;
  border-radius: 5px;
  height: 150px;
  overflow-y: scroll;
}

.code_plate::-webkit-scrollbar {
  width: 0;
}

/*  end code plate */

.codeEditor {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: #82a07a; */
  background-color: #363653d7;
  padding: 10px;
  gap: 10px;
  border-radius: 4px;
}

.chead {
  display: flex;
  width: 100%;
  color: white;
  justify-content: space-between;
}

.codeBgc {
  /* background-color: #176152; */
  background-color: #363653;
  color: white;
  padding: 4px;
}

.copy_button {
  border: none;
  font-size: 13px;
  font-weight: 600;
  padding: 0 10px;
  border-radius: 30px;
  background-color: rgb(59, 81, 45);
  color: white;
}

.copy_button:hover {
  background-color: #393838;
}

.codemirror {
  width: 100%;
}

.run_code_btn {
  border: none;
  margin: 7px 0px;
  /* background-color: #009999; */
  background-color: #363653;
  color: white;
  border-radius: 9px;
  font-size: 18px;
  padding: 4px 10px;
}

.aboutus-container {
  width: 100%;
  font-family: Arial, sans-serif;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.tab:hover {
  background-color: #f0f0f0;
}

.tab.active {
  border-bottom: 2px solid #000;
  font-weight: bold;
}

.tab-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-top: none;
}

.blog_view_btn {
  text-decoration: none;
  background-color: #363653;
  color: white;
  padding: 6px 10px;
  border-radius: 50px;
}

.blog_view_btn:hover {
  text-decoration: none;
  background-color: #363653d4;
  color: white;
  padding: 6px 10px;
  border-radius: 50px;
}

.sign-in {
  border: none;
  width: 80px;
  font-weight: bold;
  border-radius: 50px;
  padding: 5px;
  background-color: #f2f3b7;
  text-align: center;
}

.sign-out {
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 5px;
}

.sign-out:hover {
  background-color: rgb(240, 237, 237);
}

.signinleft {
  display: flex;
  justify-content: center;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.signinright {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #a779d1;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.login_btn {
  border: none;
  width: fit-content;
  padding: 10px;
  border-radius: 30px;
}

.dropdown-menus {
  position: absolute;
  width: 320px;
  top: 50px;
  right: 0px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.pay_now_btn {
  background-color: #363653;
  color: white;
  padding: 6px;
  text-decoration: none;
  border-radius: 10px;
  width: 50%;
  text-align: center;
}

.pay_now_btn:hover {
  text-decoration: none;
  background-color: #363653c1;
}


.remove_underline {
  text-decoration: none;
  position: relative
}

.remove_underline::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.remove_underline:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}



.chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  padding: 10px;
  border-radius: 50%;
  background-color: black;
  cursor: pointer;
}

.chat_icon_image {
  height: 30px;
  width: 30px;
  color: white;
}

.paynow_btn {
  background-color: #0A1538;
  border-radius: 20px;
  padding-right: 15px;
  padding-left: 15px;
}

.paynow_btn:hover {
  background-color: #13235a;
  border-radius: 20px;
  padding-right: 15px;
  padding-left: 15px;
}

.test_card_body {
  background: rgb(57, 49, 175);
  background: linear-gradient(90deg, rgba(57, 49, 175, 1) 0%, rgba(0, 198, 255, 1) 100%);
  color: white;
}

.test_card_body .card-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.quiz_card_button {
  background-color: #4d4d92;
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 80%;
  margin: 10px;
  text-decoration: none;
}

.quiz_card_button:hover {
  text-decoration: none;
}

.exercise_login_btn {
  border: none;
  padding: 10px 20px;
  background-color: #005c54;
  color: white;
  border-radius: 5px;
  margin-right: 10px
}

.quiz_list {
  background-color: rgba(128, 128, 128, 0.149);
  border-radius: 8px;
}


.single_quiz_list {
  border: 7px solid rgba(195, 155, 155, 0.337);
  border-radius: 10px;
  display: flex;
  padding: 20px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.single_quiz_list_link {
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  color: #005c54;
}

.single_quiz_list_link:hover {
  color: rgb(114, 108, 108) !important;
}

.design_view {
  margin-top: 20px;
  background-color: #25d366;
  color: white;
  width: 70%;
  border-radius: 10px;
  padding: 10px;
}

@media screen and (max-width: 480px) {
  .design_view {
    width: 100%;
  }

  .single_quiz_list {
    margin-bottom: 10px;
  }
}

.back_arrow {
  font-size: 30px;
  animation: moveRight 1s ease-in-out infinite;
}

.back_arrow:hover {
  color: black;
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

.heading_of_gpt_page {
  color: #0d6efd;
  font-size: 53px;
  font-weight: bold;
}

.heading_of_gpt {
  color: #000000;
  font-size: 53px;
  font-weight: bold;
}

@media screen and (max-width:767px){
  .heading_of_gpt_page{
    font-size: 20px;
  }
  .heading_of_gpt{
    font-size: 20px;
  }
  .main_div{
    width: 100%;
  }
}


.main_div{
  background-color: black;
  width: 70%;
  align-items: center;
  border-radius: 10px;
}

@media screen and (max-width:767px){
  .main_div{
    width: 90%;
  }
  .paynow_btn{
    margin: 3px;
  }
}

.chat_gpt_input{
  width: 100%;
  margin-bottom: 10px;
  color: white;
  background-color: transparent;
  outline: none;
}

.generate_btn{
  border-radius: 20px;
  border: none;
  padding: 5px 10px;
  margin: 10px;
}


.typing_text_area{
  margin-top: 10px;
  width: 100%;
  border: 3px solid gray;
  border-radius: 5px;
  min-height: 200px;
}


.navbar-light .navbar-nav .nav-link {
  color: #fff;
  text-align: center;
  position: relative;
  transition: color 0.3s ease;
}

.navbar-light .navbar-nav .nav-link::after {
  content: '';
  position: absolute;
  width: 0; 
  height: 2px;
  background: #ffffff; 
  bottom: 0;
  left: 50%; 
  transform: translateX(-50%);
  transition: width 0.3s ease; 
}

.navbar-light .navbar-nav .nav-link:hover::after {
  width: 100%; 
}

.whiteBoard_sidebar{
    background-color: #e6e881;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
  }

.tools_page_bg{
background-image: url(../public/images/bg_img.jpg);
}