/* h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
} */

.divider:after,
.divider:before {
  content: '';
  flex: 1;
  height: 1px;
  background: #eee;
}
.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.email-input {
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  outline: none;
  transition: border-color 0.3s;
}

.email-input::placeholder {
  color: #999;
}

.email-input:focus {
  border-color: #007bff;
}

.email-input:hover {
  border-color: #999;
}

.login_background {
  background: #fff3f8;
}

.otp-input-fields {
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.otp-input-fields input {
  height: 38px;
  width: 100%;
  border-radius: var(--otpInputRadius);
  border: 1px solid gray;
  text-align: center;
  outline: none;
}

.otp-input-fields input::-webkit-outer-spin-button,
.otp-input-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input-fields input[type='number'] {
  -moz-appearance: textfield;
}

.email-input {
  width: 340px;
}

@media screen and (max-width: 480px) {
  .email-input {
    width: 230px;
  }
}

@media screen and (max-width: 1115px) {
  .email-input {
    width: auto;
  }
}

@media screen and (max-width: 999px) {
  .img-fluid {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .img-fluid {
    display: block;
    height: 400px;
    width: 496px;
  }
}

.form_background {
  background: white;
  padding: 6px;
  padding-top: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.or_design {
  background-color: rgb(90, 89, 89);
  color: white;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  line-height: 30px;
}

.sign_in_font {
  font-size: 15px;
  margin-top: auto;
  font-weight: bold;
}
.social_icon {
  margin-left: -23px;
  margin-top: 19px;
  height: 69px;
  width: 69px;
}
